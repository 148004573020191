import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Vinyl Haven'ı Keşfedin
			</title>
			<meta name={"description"} content={"Kendinizi Vinyl Haven'ın Zengin Tarihi ve Canlı Topluluğuna Bırakın\n"} />
			<meta property={"og:title"} content={"Hakkımızda | Vinyl Haven'ı Keşfedin"} />
			<meta property={"og:description"} content={"Kendinizi Vinyl Haven'ın Zengin Tarihi ve Canlı Topluluğuna Bırakın\n"} />
			<meta property={"og:image"} content={"https://ottomanodyssey.live/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ottomanodyssey.live/img/8724a4c4e98891b44c2bedbbf710423a-music-record-player-icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Bizim Özümüz
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Vinyl Haven bir plak dükkanından çok daha fazlasıdır - müzik severler için kültürel bir merkez, plakların analog sıcaklığının dijital çağla zarafet ve özgünlükle buluştuğu bir sığınaktır. Yolculuğumuz basit bir fikirle başladı: Müziğin en somut haliyle kutlanabileceği, nesiller ve türler arasında insanları birbirine bağlayan bir alan yaratmak.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://ottomanodyssey.live/img/6.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
				/>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0 40px 0"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				margin="0px"
				font="--headline2"
				color="--dark"
				max-width="850px"
				width="50%"
				md-width="100%"
				md-margin="0px 0px 16px 0px"
			>
				Vinyl Haven'ın Nabzı
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="50%"
				md-width="100%"
				md-margin="16px 0px 0px 0px"
				sm-margin="16px 0px 32px 0px"
			>
				Hikayemiz kökenlerimizle değil, duvarlarımızın içinde, plakların yivleri arasında ve topluluğumuzun kalplerinde paylaşılan sayısız anla yazılmıştır. Vinyl Haven, sadece müzik değil, ruhlarında yankılanan bir deneyim arayışıyla kapılarımızdan içeri girenlerin tutkusuyla büyüyor. Burada her plağın bir hikayesi vardır ve her ziyaret yeni bir şey keşfetmek için bir şanstır.
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image max-width="340px" src="https://ottomanodyssey.live/img/7.jpg" md-margin="0px 0px 20px 0px" />
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
					Özümüz Neden Önemlidir?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					- Her Kulak İçin Seçilmiş Seçkiler: Raflarımızda her biri kalitesi ve anlattığı hikaye için özenle seçilmiş çok çeşitli türler ve dönemler bulunur. Bu sadece müzikle ilgili değil - her albümün sizi davet ettiği yolculukla ilgili.
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					- Başka Hiçbir Şeye Benzemeyen Bir Dinleme Deneyimi: Dinleme istasyonlarımız Vinyl Haven'ın kalbidir ve eve götürmeye karar vermeden önce kendinizi müziğin içinde kaybetmeniz için tasarlanmıştır. Bu, müziğin derinlemesine ve acele etmeden deneyimlenmesi gerektiğine olan inancımızın bir kanıtıdır.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
					- Topluluk ve Bağlantı: Vinyl Haven, ortak müzik zevkleri ve yeni keşifler üzerine dostlukların kurulduğu bir yerdir. Etkinliklerimiz ve buluşmalarımız, müziğin insanları bir araya getirme gücünün kutlamalarıdır.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});